/* src/Login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the top */
    min-height: 100vh;
    background-color: #434a48;
    padding: 20px;
    position: relative;
}

.login-form {
    /*background: white;*/
    background-color: #009999;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 95%;
    max-width: 400px;
    margin-top: 33.33vh; /* Position form one-third from the top */
    transform: translateY(-33.33%);
}

    .login-form h2 {
        margin-bottom: 20px;
        text-align: center;
    }

.form-group {
    margin-bottom: 15px;
}

    .form-group label {
        display: block;
        margin-bottom: 5px;
    }

    .form-group input {
        width: 95%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #434a48;
    color: #009999;
    font-size: 16px;
    cursor: pointer;
}

    button:hover {
        background-color: #cbcfc9;
    }

@media (max-width: 600px) {
    .login-form {
        padding: 15px;
    }

    .form-group input {
        padding: 8px;
    }

    button {
        padding: 8px;
        font-size: 14px;
    }
}
